<template>
    <BeforeSettingPassword/>
</template>

<script>
import BeforeSettingPassword from '../../components/auth/BeforeSettingPassword'
export default {

    components: {
        BeforeSettingPassword
    }
}
</script>

<style>

</style>
