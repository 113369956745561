<template>
<div>
    <div class="login-div">
        <div class="row">
            <div class="col-lg-8 col-12">

            </div>
            <div class="col-lg-4 col-12 login-container text-center">

                <img src="../../assets/images/logo2.png" height="50" class="mt-60">
                <div class="" style="min-height:70vh; display:flex; align-items:center; flex-flow:column; justify-content:center;">

                    <p class="mb-4" v-if="errorMessage">{{tran.linkExpired}}</p>
                    <!-- <div class="form-group">
                        <button type="button" class="submit-btn">
                                {{tran.resendLink}}
                        </button>
                       </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import trans from '../../../translations'
import {
    mapState
} from "vuex";
import http from "../../http/index";
import api from "../../apis/index";
export default {
    data() {

        return {

            tran: {},
            errorMessage:false
        }
    },

methods:{

  verifyLink() {
          
            let vm = this

            http.post(api.verifyActivatorLink, this.$route.query)
                .then(response => {
           localStorage.setItem("activatorQuery", JSON.stringify(this.$route.query));
                        this.$router.push('/set/password')
                            this.$helpers.log(response);

                   
                })
                .catch(error => {
                  
                    this.$helpers.log(error.response);
                    vm.errorMessage = true
                });

        },
},


    computed: {
        ...mapState({

            formdata: state => state.auth.email,

        }),
    },
    beforeMount() {

        this.verifyLink()

        this.$helpers.log(this.$route.query, 'requid query')

        const routeToken = this.$route.query.ua;
        //now dispatch action
      

    },
       computed: {
        ...mapState({
            formdata: state => state.auth.signUp,
         
            token: state => state.auth.token
        }),
    },
    mounted() {


        this.tran = trans
      
    }
}
</script>

<style>

</style>
